<template>
  <div class="container">
    <div class="left">
      <Carousel v-model="value" autoplay radius-dot arrow="never" loop>
        <CarouselItem class="carouselitem">
          <div class="demo-carousel">
            <img src="../assets/images/banner-1.png" />
          </div>
        </CarouselItem>
        <CarouselItem class="carouselitem">
          <div class="demo-carousel">
            <img src="../assets/images/banner-2.png" />
          </div>
        </CarouselItem>
        <CarouselItem class="carouselitem">
          <div class="demo-carousel">
            <img src="../assets/images/banner-3.png" />
          </div>
        </CarouselItem>
      </Carousel>
    </div>
    <div class="right">
      <div class="header">
        <img class="tx" src="../assets/logo_red.png" />
        <h1 class="h1">新华区房源超市社区后台</h1>
        <!-- <div class="ll">
          基于 Admin Plus、iCRUD 和若依的企业级多租户权限管理开发平台
        </div> -->
      </div>
      <div class="demo-login">
        <Login @on-submit="handleSubmit" class="login">
          <UserName name="username" :value="userInfo.username" />
          <Password name="password" :value="userInfo.password" />
          <div class="demo-auto-login">
            <!-- <Checkbox v-model="autoLogin" size="large">自动登录</Checkbox>
            <a>忘记密码</a> -->
          </div>
          <Submit />
        </Login>
      </div>
      <div class="footer">
        <div class="footers" style="margin-top: 10px;">
          Copyright © 2023 新华区房源超市
        </div>
        <div class="footers" style="margin-top: 10px;">
          版权所有 豫ICP备05082262号-1 服务电话：0375-1586599 / 25645868
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from "@/api/admin";
export default {
  data() {
    return {
      value: 0,
      autoLogin: true,
      userInfo: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    handleSubmit(valid, { username, password }) {
      if (valid) {
        const msg = this.$Message.loading({
          content: '正在登录...',
          duration: 0
        });
        login({
          username,
          password,
        }).then((res) => {
          if (res.code == 0) {
            localStorage.token = res.data.mToken;
            localStorage.token_outtime = (new Date).getTime() + 3600 * 2 * 1000;
            localStorage.userInfo = JSON.stringify({ username });
            setTimeout(() => {
              this.$router.push("/admin-index")
            }, 500)
          } else {
            this.$Modal.info({
              title: "消息提醒：",
              content: "账号密码错误",
            });
          }
        }).finally(() => {
          msg();
        });
      }
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100vh;
}

.demo-carousel {
  text-align: center;
}

.ivu-carousel {
  height: 100vh;
  position: relative;
}

.ivu-carousel-item {
  margin-top: 130px;
}

.left {
  width: 50%;
  height: 100%;
  background-color: #1850c0;
}

img {
  width: 700px;
  height: 100%;
}

h3.ivu-typography {
  color: white;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.35;
}

div.kk {
  color: white;
  font-size: 14px;
}

.right {
  width: 50%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  text-align: center;
}

.demo-login {
  margin-top: 35px;
  display: flex;
  justify-content: center;
}

.login {
  width: 424px;
}

.tx {
  width: 100px;
  height: 100px;
  margin-top: 50px;
  border-radius: 50%;
  background: #f2f2f3;
}

.h1 {
  margin-top: 5px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 38px;
}

.ll {
  font-size: 14px;
  color: #515a6e;
  margin-top: 5px;
}

.demo-auto-login {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.middle {
  margin-top: 28px;
  margin-left: 5.5px;
}

.ivu-btn {
  margin: 0 7.5px;
  color: #515a6e;
  font-size: 14px;
}
</style>