import request from "./request";
const Req = new request()
export function list({ keyword, page, rows, location_id, status, start_date, end_date }) {
    return Req.get({
        url: '/book/list',
        data: {
            keyword, page, rows, location_id, status, start_date, end_date
        }
    })
}


export function sure(book_id) {
    return Req.post({
        url: '/book/confirm',
        data: {
            book_id
        }
    })
}

export function free(book_id) {
    return Req.post({
        url: '/book/free',
        data: {
            book_id
        }
    })
}