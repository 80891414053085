import axios from "axios";

let config = {
  baseURL: process.env.baseURL || process.env.apiUrl,
  timeout: 2 * 1000,
};

const _axios = axios.create(config);
_axios.interceptors.request.use(
  function (config) {
    _axios.defaults.headers.common['Mtoken'] = localStorage.token;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  function (response) {
    // console.log(response);
    return response.data;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default _axios

