import request from "./request";
const Req = new request()
export function list(page = 0, rows = 10) {
    return Req.get({
        url: '/book/locationList',
        data: {
            page,
            rows
        }
    })
}
export function del(location_id) {
    return Req.post({
        url: '/location/delete',
        data: {
            location_id
        }
    })
}
export function add(data) {
    return Req.post({
        url: "/location/create",
        data: data
    })
}
export function update(data) {
    return Req.post({
        url: "/location/edit",
        data: data
    })
}
