import { createRouter, createWebHashHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import ErrorPage from "../views/ErrorPage.vue";

import Book from "../views/book/List.vue";
import LocationList from "../views/location/List.vue";
const routes = [
  {
    path: '/',
    name: 'home',
    component: LoginView,
    meta: {
      title: '管理台 - 登陆',
      keepAlive: false
    }
  }, {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      title: '管理台 - 登陆',
      keepAlive: false
    }
  }, {
    path: '/admin-index',
    name: 'admin-index',
    component: () => import("../views/MainView.vue"),
    children: [{
      path: '',
      name: 'Main',
      component: LocationList
    },
    {
      path: "location",
      name: "社区",
      component: LocationList
    },
    {
      path: "book",
      component: Book,
    }
    ]
  },
  {
    path: '/404',
    name: 'NotFound',
    component: ErrorPage,
    hidden: true
  },
  {
    path: "/:pathMatch(.*)",
    name: "noMatch",
    redirect: "/404",
    hidden: true
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to) => {
  document.title = "新华区房源超市管理后台"
  // if (to.meta.title) {
  //   document.title = to.meta.title
  // }
  if (to.path == "/" || to.path == "/login") {
    // console.log("正常访问 没啥可干的");
    return true
  } else {
    let time = (new Date).getTime();
    // 如果当前时间戳比缓存超期时间大 就强制跳转到登录页面
    // 或者 token没有内容也跳转到登录页面
    if (!localStorage.token) {
      router.push("/login")
    }
    if (time + 5000 > localStorage.token_outtime - 0) {
      router.push("/login")
    } else {
      // 验证token是否合法
      return true
    }
  }
})
export default router
