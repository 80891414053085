/** 管理员API接口文件 */
import request from "./request";
const Req = new request()
export function login({ username, password }) {
    return Req.post({
        url: "/passport/login",
        data: { account: username, password }
    })
}

export function loginout() {
    return Req.post({
        url: "/loginout",
        data: {}
    })
}

export function list(keyword) {
    return Req.get({
        url: '/manager/list',
        data: {
            keyword
        }
    })
}
export function getone(id) {
    return Req.get({
        url: `/manager/detail?manager_id=${id}`,
        data: {
        }
    })
}
export function del(manager_id) {
    return Req.post({
        url: '/manager/delete',
        data: {
            manager_id
        }
    })
}
export function add({ account, password, location_list }) {
    return Req.post({
        url: "/manager/create",
        data: {
            account, password, location_list
        }
    })
}

export function update(data) {
    return Req.post({
        url: "/manager/edit",
        data: data
    })
}

export function updatePwd(manager_id, password) {
    return Req.post({
        url: "/manager/password",
        data: { manager_id, password }
    })
}
