import Config from "@/utils/config";
import _axios from "@/plugins/axios";
class request {
    constructor() {
        if (localStorage.token) {
            _axios.defaults.headers.common['Mtoken'] = localStorage.token
        }
    }
    get({
        url = '',
        data = null
    }) {
        if (url.indexOf("http") === 0) {
            return _axios.get(url, {params:data})
        } else {
            return _axios.get(Config.bashUrl + url, {
                params: data
            })
        }
    }
    post({
        url = '',
        data = null
    }) {
        if (url.indexOf("http") === 0) {
            return _axios.post(url, data)
        } else {
            return _axios.post(Config.bashUrl + url, data)
        }
    }
}

// 导出request类
export default request