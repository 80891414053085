<template>
  <div class="list">
    <PageHeader title="社区信息" hidden-breadcrumb>
      <template #action>
      </template>
    </PageHeader>

    <Table style="margin-top: 10px;" border ref="selection" :columns="columns" :data="locations">
    </Table>

  </div>
</template>
<script>
import { list } from "@/api/location";

export default {
  name: "BookList",
  data() {
    return {
      columns: [
        {
          title: "社区编号",
          key: "location_id"
        },
        {
          title: "社区名",
          key: "name",
        }
      ],
      locations: [],
    };
  },
  beforeMount() {
    this.getList()
  },
  methods: {
    getList() {
      list({}).then((res) => {
        if (res.code == 0) {
          this.locations = res.data;
        }
      });
    }
  },
};
</script>

<style scoped>
.list {
  width: 100%;
  margin: 0 10px;
  padding: 10px;
  background: #fff;
  margin-top: 50px;
}

.padding-tb {
  padding-bottom: 10px;
}

.option button {
  margin-right: 10px;
}
</style>