<template>
  <div class="list">
    <PageHeader title="预定列表" hidden-breadcrumb>
      <template #action>
        <Space size="large" wrap>
          <DatePicker type="date" placeholder="开始时间" v-model="start_date" style="width: 120px" />
          <DatePicker type="date" placeholder="结束时间" v-model="end_date" style="width: 120px" />

          <Select v-model="location_id" style="width:200px;text-align: center;">
            <Option :value="-1">全选</Option>
            <Option v-for="item in locations" :value="item.location_id" :key="item.name">{{ item.name }}</Option>
          </Select>
          <Input v-model="keyword" placeholder="输入要查询的用户 姓名/身份证/手机号..." clearable style="width: 200px;" />
          <Button type="primary" @click="getBooks">
            <Icon type="md-search" size="24" />
          </Button>
        </Space>
      </template>
    </PageHeader>

    <Table size="small" class="table" border :columns="columns" :data="books">
      <template #house-info="{ row }">
        <div>{{ row.building }} / {{ row.unit }} / {{ row.house_no }}/<span style="color: red;">{{ row.area }}m&sup2;</span></div>
      </template>
      <template #status="{ row }">
        <Button size="small" v-if="row.status == 30" type="warning">已失效</Button>
        <Button size="small" v-if="row.status == 20" type="success">已预定</Button>
        <Button size="small" v-if="row.status == 10" type="error">预定中</Button>
      </template>
      <template #pretime="{ row }">
        <span>{{ row.create_time }}</span>
      </template>
      <template #options="{ row }">
        <!-- , index -->
        <div class="options">
          <Button :disabled="row.status != 10" size="small" type="primary" @click="sureBook(row.book_id)">确定房源</Button>
          <!-- <Button :disabled="row.status != 10" size="small" type="error"
            @click="removeBook(row.book_id, index)">释放房源</Button> -->
        </div>
      </template>
    </Table>
    <div style="margin-top: 20px;">
      <Page show-total :total="page.total" show-sizer @on-change="pageChange" @on-page-size-change="rowsChange" />
    </div>

    <!-- <Button @click="removeBook(1)">确定</Button> -->

    <Modal v-model="modal" title="确认弹窗" @on-ok="handleFunciton">
      <p>{{ modalMsg }}</p>
    </Modal>
  </div>
</template>

<script>
import { list as location } from "@/api/location";
import { list, sure, free } from "@/api/book";
import moment from "moment";
export default {
  name: "BookList",
  data() {
    return {
      modal: false,
      modalMsg: "",
      columns: [
        {
          title: "社区",
          key: "location_name",
          width: 150
        },
        {
          title: "姓名",
          key: "name",
          width: 75
        },
        {
          title: "身份证号",
          key: "ID"
        },
        {
          title: "手机号",
          key: "mobile",
          width: 115
        },
        {
          title: "楼盘名称",
          key: "project_name",
          width: 120
        },
        {
          title: "预定房屋",
          slot: "house-info"
        },
        {
          title: "预定状态",
          slot: "status",
          width: 90
        },
        {
          title: "预定时间",
          slot: "pretime"
        },
        {
          title: "操作",
          slot: "options",
          width: 190
        }
      ],
      books: [],
      book_id: 0,
      keyword: "",
      start_date: "",
      end_date: "",
      locations: [],
      location_id: -1,
      page: {},
      status: 0
    };
  },
  beforeMount() {
    location().then(res => {
      if (res.code == 0) {
        this.locations = res.data
      }
    }).then(() => {
      this.getBooks({})
    })
  },
  methods: {
    getBooks({ page = 1 }) {
      let params = {
        keyword: this.keyword,
        rows: this.rows,
        status: this.status,
        location_id: -1,
        start_date: 0,
        end_date: 0,
        page: page
      }
      if (!params.keyword) {
        delete params.keyword
      }
      if (!params.status) {
        delete params.status
      }

      if (this.start_date) {
        params.start_date = moment(this.start_date).format('YYYY-MM-DD');
      } else {
        delete params.start_date
      }
      if (this.end_date) {
        params.end_date = moment(this.end_date).format('YYYY-MM-DD');
      } else {
        delete params.end_date
      }

      if (this.location_id != -1) {
        params.location_id = this.location_id
      } else {
        delete params.location_id
      }
      list(params).then(res => {
        if (res.code == 0) {
          this.page = {
            total: res.data.total,
            per_page: res.data.per_page,
            current_page: res.data.current_page,
            last_page: res.data.last_page,
          }
          this.books = res.data.data;
        }
      })
    },
    sureBook(book_id) {
      this.modalMsg = `确认预定房源编号为${book_id}的房源吗?`
      this.modal = true;
      this.book_id = book_id;
      this.handleType = "sure";
    },
    handleFunciton() {
      if (this.handleType == "sure") {
        sure(this.book_id).then(res => {
          if (res.code == 0) {
            this.$Notice.success({
              title: res.msg
            })
          } else {
            this.$Notice.error({
              title: res.msg
            })
          }
          this.getBooks({})
        }).finally(() => {
          this.modal = false;
          this.modalMsg = "";
        })
      } else {
        free(this.book_id).then(res => {
          if (res.code == 0) {
            this.$Notice.success({
              title: res.msg
            })
          } else {
            this.$Notice.error({
              title: res.msg
            })
          }
        }).finally(() => {
          this.modal = false;
          this.modalMsg = "";
        })
      }
    },
    removeBook(book_id) {
      this.modalMsg = `确认释放房源编号为${book_id}的房源吗?`
      this.modal = true;
      this.book_id = book_id;
      this.handleType = "remove";
    },
    pageChange(e) {
      this.getBooks({ page: e })
    },
    rowsChange(e) {
      this.rows = e
    }
  },
};
</script>

<style scoped>
.list {
  width: 100%;
  margin: 0 10px;
  padding: 10px;
  margin-top: 50px;
}

.table {
  margin-top: 20px;
}

.options {
  display: flex;
  justify-content: space-around;
}

.search {
  background: #fff;
  padding: 10px;
  margin-top: 10px;
}
</style>